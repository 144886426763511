.text {
  padding: 16px 10px;
  line-height: 20px;
  font-size: 14px;
  white-space: pre-wrap;
}

.MUIcard {
  box-shadow: none !important;
  width: 470px;
  border: 1px solid #ddd;
}

@media (max-width: 480px) {
  .MUIcard {
    width: 345px;
    margin: 10px 0 0 0;
  }
}

.MUIcard.darkenOnHover:hover {
  opacity: 0.5;
}

.facebookAdWrapper {
  width: auto;
}

.facebookAdWrapper.fullscreen {
  max-width: 455px;
}

.facebookAdWrapper p,
.facebookAdWrapper h5 {
  margin: 0;
}

.header {
  height: 30px;
  padding: 16px 4px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header > div:first-child {
  display: flex;
}

.header__profileImage {
  width: 40px;
  border-radius: 50%;
  margin-right: 8px;
  height: 40px;
}

.header__placeholderProfileImage {
  opacity: 0.3;
}

.header__text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  margin-right: 8px;
  padding-top: 4px;
}

.header__text > h5 {
  color: #365899;
  font-size: 14px;
}

.header__text > p {
  display: flex;
  color: #616770;
  font-size: 12px;
  line-height: 19px;
  padding-top: 2px;
}

.header__text > p > img {
  height: 17px;
  width: 17px;
}

.likeButtonAndKabob {
  width: 124px;
  margin-bottom: 14px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e9ebee;
  padding: 8px 10px 10px 10px;
  width: min-content;
  min-width: calc(100% - 20px);
}

.content__text {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 340px;
  height: 60px;
}

@media (max-width: 480px) {
  .content__text {
    width: 235px;
  }
}

.content__text div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content__text__domain {
  font-size: 12px;
  text-transform: uppercase;
  color: #606770;
}

.content__text__name {
  font-size: 17px;
  font-weight: bold;
  color: #333;
}

.content__text__description {
  font-size: 15px;
  color: #616770;
}

.learnMoreButton {
  width: 90px;
}

.likeCount {
  padding: 8px 4px 0 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #616770;
}

.likeCount img {
  margin-right: 1px;
}

.ctaButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px 0 10px 16px;
  color: #606770;
  font-weight: bold;
  font-size: 13px;
}

.ctaButtons div {
  display: flex;
  align-items: center;
}

.ctaButtons img {
  margin-right: 5px; 
}

.ctaButtons__profileImage {
  width: 16px;
  border-radius: 50%;
  height: 16px;
}

.horizontalRule {
  width: 445px;
  height: 1px;
  border: none;
  background-color: #ddd;
}

.imageWrapper {
  position: relative;
}
