html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
  background-color: #dbdbdb;
  height: 100%;
}

#root {
  margin: auto;
  height: 100%;
  background-color: white;
}
